var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      {
        staticClass: "user-info flex b-w f-ai-c",
        on: { click: _vm.editUserInfo },
      },
      [
        _c("img", { attrs: { src: _vm.avatar } }),
        _c("span", { staticClass: "user-name" }, [_vm._v(_vm._s(_vm.name))]),
        _c("i", { staticClass: "r-icon" }),
      ]
    ),
    _c("ul", { staticClass: "b-w flex" }, [
      _c("li", { on: { click: _vm.toPatients } }, [
        _c("i", { staticClass: "l-icon l-icon-1" }),
        _c("span", [_vm._v("就诊人管理")]),
        _c("i", { staticClass: "r-icon" }),
      ]),
      _c("li", { on: { click: _vm.toOrders } }, [
        _c("i", { staticClass: "l-icon l-icon-2" }),
        _c("span", [_vm._v("药品订单")]),
        _c("i", { staticClass: "r-icon" }),
      ]),
      _c("li", { on: { click: _vm.toConsultations } }, [
        _c("i", { staticClass: "l-icon l-icon-4" }),
        _c("span", [_vm._v("问诊单")]),
        _c("i", { staticClass: "r-icon" }),
      ]),
      _c("li", { on: { click: _vm.toOn } }, [
        _c("i", { staticClass: "l-icon l-icon-3" }),
        _c("span", [_vm._v("关于")]),
        _c("i", { staticClass: "r-icon" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }